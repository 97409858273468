/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap");

* {
    font-family: "Noto Sans JP", sans-serif;
}
/* for table header */
.rdt_TableHeadRow {
    height: 3rem;
    font-size: 16px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

/* for each individual cell */
.rdt_TableCell {
    font-size: 14px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-weight: 100 !important;
    cursor: pointer;
}
.popper-class {
    z-index: 9999;
}

.custom {
    width: 30.8rem;
    height: 2.5rem;
    padding: 7px;
    font-size: 16px;
    padding-left: 14px;
    padding-right: 10px;
    border-radius: 5px;
    border: 1px solid #ffffff;
    background-color: transparent;
    color: rgb(0, 0, 0) !important;
    border-color: #e0e4e9;
    border-radius: "0.375rem";
}
.custom:focus-visible {
    outline-color: #428ae1;
}

.custom-date {
    width: 14rem !important;
}

.form-date {
    width: 208px;
    height: 28px;
    padding: 7px;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    outline: 2px solid transparent;
    border: 1px solid;
    background-color: transparent;
    color: #000 !important;
    border-color: #e2e8f0;
    border-radius: 0.125rem;
    transition: 0.2s all;
}
.form-date:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 1px #3965ff;
    border-color: #3965ff;
}

.custom::placeholder {
    font-size: 0.8rem;
}

.react-datepicker-wrapper {
    width: max-content;
}

.multi-select {
    width: 13rem;
    height: 2rem;
    font-size: 0.8rem;
    height: 0.8rem;
    margin-bottom: 1rem;
}

.multi-select::placeholder {
    font-size: 0.8rem;
}

.rmsc {
    width: 13rem;
    height: 2rem;
    font-size: 0.8rem;
    height: 0.8rem;
    margin-bottom: 1rem;
}

.rmsc .dropdown-container {
    height: 1.8rem;
    display: flex;
    align-items: center;
}

.cropContainer {
    position: relative;
    width: 100%;
    height: 300px;
    background-color: #333;
}

.selectReact {
    height: 5px !important;
    width: 13rem;
    font-size: x-small;
    padding: 0 !important;
}

.react-select-component {
    height: 10px;
}

@media (min-width: 992px) and (max-width: 1080px) {
    .react-date-picker {
        width: 100%;
    }
    .wrapper-react-datepicker {
    }
}

.terminateDate {
    width: 100% !important;
}
.dataPicker-inner {
    width: 49% !important;
}
.dataPicker-inner .form-date {
    width: 100%;
}

.scoll-pane::-webkit-scrollbar {
    display: none;
}
.border-red {
    border-color: #fc8181;
    box-shadow: 0 0 0 1px #fc8181;
}
